import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  ContentTitle,
  ContentContainer,
  FormLabel,
  FormBlock,
  FormNumberInput,
  FormSubmitButton,
  CancelButton
} from '../style/export';
import HintText from '../style/hint-text';
import { Map } from 'immutable';
import FormNumberUnitsInput from '../style/form-number-units-input';

export default class ProjectConfigurator extends Component {

  constructor(props, context) {
    super(props, context);

    let scene = props.state.scene;

    const {
      wallHeight, wallThickness, materialExcess,
    } = (scene.getIn(['meta', 'config']) || new Map()).toJS()

    this.state = {
      dataWidth: scene.width,
      dataHeight: scene.height,

      wallHeight: wallHeight != null ? wallHeight : 300,
      wallThickness: wallThickness != null ? wallThickness : 20,

      materialExcess: materialExcess != null ? materialExcess : 5,
    };
  }

  validate() {
    let {dataWidth, dataHeight} = this.state;
    dataWidth = parseInt(dataWidth);
    dataHeight = parseInt(dataHeight);
    if (dataWidth <= 100 || dataHeight <= 100) {
      return 'Scene size too small';
    }
    return true;
  }

  onSubmit(event) {
    event.preventDefault();

    const validation = this.validate();
    if (validation !== true) {
      alert(validation);
      return;
    }

    let {projectActions} = this.context;

    let {dataWidth, dataHeight} = this.state;
    dataWidth = parseInt(dataWidth);
    dataHeight = parseInt(dataHeight);

    projectActions.setProjectProperties({
      width: dataWidth,
      height: dataHeight,
    });

    let {wallHeight, wallThickness, materialExcess} = this.state;
    projectActions.mergeMeta({
      config: {
        wallHeight: parseFloat(wallHeight),
        wallThickness: parseFloat(wallThickness),
        materialExcess: parseFloat(materialExcess),
      }
    });
  }


  render() {
    let {width, height} = this.props;
    let {
      dataWidth, dataHeight,
      wallHeight, wallThickness,
      materialExcess,
    } = this.state;
    let {projectActions, translator} = this.context;

    return (
      <ContentContainer width={width} height={height}>
        <ContentTitle>Project Configuration</ContentTitle>
        <form onSubmit={e => this.onSubmit(e)}>

          <h4>Project Dimensions</h4>
          <FormBlock style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'end',
            gap: '1em',
          }}>
            <div>
              <FormLabel htmlFor='width'>Width</FormLabel>
              <FormNumberInput
                id='width'
                placeholder='Width'
                value={dataWidth}
                onChange={e => this.setState({dataWidth: e.target.value})}
                immediate={true}
                style={{ width: 'unset' }}
              />
            </div>
            <FormLabel style={{ lineHeight: '1.25' }}>
              &times;
            </FormLabel>
            <div>
              <FormLabel htmlFor='height'>Height</FormLabel>
              <FormNumberInput
                id='height'
                placeholder='Height'
                value={dataHeight}
                onChange={e => this.setState({dataHeight: e.target.value})}
                immediate={true}
                style={{ width: 'unset' }}
              />
            </div>
          </FormBlock>

          <h4>Defaults</h4>
          <FormBlock style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'end',
            gap: '1em',
          }}>
            <div>
              <FormLabel htmlFor='wallHeight'>Wall Height</FormLabel>
              <FormNumberUnitsInput
                id='wallHeight'
                placeholder='Wall Height'
                value={wallHeight}
                onChange={val => this.setState({wallHeight: val})}
                unit={'cm'}
                baseUnit={'cm'}
                immediate={true}
                style={{ width: 'unset' }}
                min={0}
              />
            </div>
            <div>
              <FormLabel htmlFor='wallThickness'>Wall Thickness</FormLabel>
              <FormNumberUnitsInput
                id='wallThickness'
                placeholder='Wall Thickness'
                value={wallThickness}
                onChange={val => this.setState({wallThickness: val})}
                unit={'cm'}
                baseUnit={'cm'}
                immediate={true}
                style={{ width: 'unset' }}
                min={0}
              />
            </div>
          </FormBlock>

          <h4>Estimator</h4>
          <FormBlock>
            <div>
              <FormLabel htmlFor='materialExcess'>Material Excess +%</FormLabel>
              <FormNumberInput
                id='materialExcess'
                placeholder='Material Excess'
                value={materialExcess}
                onChange={e => this.setState({materialExcess: e.target.value})}
                immediate={true}
                style={{ width: 'unset' }}
                min={0}
              />
            </div>
            <HintText>
              Automatic quantities will be calculated with this excess in mind.
            </HintText>
          </FormBlock>

          <table style={{float: 'right'}}>
            <tbody>
              <tr>
                <td>
                  <CancelButton
                    size='large'
                    onClick={e => projectActions.rollback()}
                  >
                    {translator.t('Cancel')}
                  </CancelButton>
                </td>
                <td>
                  <FormSubmitButton size='large'>{translator.t('Save')}</FormSubmitButton>
                </td>
              </tr>
            </tbody>
          </table>

        </form>
      </ContentContainer>
    )
  }
}

ProjectConfigurator.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  state: PropTypes.object.isRequired,
};

ProjectConfigurator.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};
