import {
  MODE_IDLE,
  MODE_3D_FIRST_PERSON,
  MODE_3D_VIEW,
  MODE_SNAPPING,
  KEYBOARD_BUTTON_CODE
} from '../constants';

import {
  rollback,
  undo,
  remove,
  toggleSnap,
  copyProperties,
  pasteProperties,
  setAlterateState
} from '../actions/project-actions';

import { sendMessage } from '../utils/xd-events';
import { loadProject } from '../utils/xd-project';

export default function xd_events() {

  return (store, stateExtractor) => {

    // Register event handlers here.
    const eventHandlers = {

      'load': (ev) => {
        const { type, data } = ev;
        loadProject(store, JSON.parse(ev.data));
        sendMessage({ type: 'on-load' });
        document.dispatchEvent(new CustomEvent('xd-load', { detail: { data } }));
      },

      'save-success': (ev) => {
        const { type, id } = ev;
        console.log("Floor plan saved successfully");
        window.alert(`Floor plan saved successfully`);
        document.dispatchEvent(new CustomEvent('xd-save-success', { detail: { id } }));
      },

      'save-error': (ev) => {
        const { type, id, error } = ev;
        console.error("Error saving floor plan", error, ev);
        window.alert(`An error occurred when saving floor plan. (id=${id}, err="${error}")`);
        document.dispatchEvent(new CustomEvent('xd-save-error', { detail: { id, error } }));
      },

    };

    window.addEventListener('message', (msg) => {
      console.log("Message received", msg);
      if (!msg)
        return;
      const ev = msg.data;
      if (!ev)
        return;
      const type = ev.type;
      if (typeof(type) != 'string')
        return;
      const handler = eventHandlers[type];
      if (typeof(handler) != 'function')
        return;
      handler(ev);
    });

    window.addEventListener('beforeunload', () => {
      // Save floor plan before leaving.
    });

    //window.addEventListener('load', () => {
      // Let Next know that the editor is ready.
      sendMessage({ type: 'on-init' });
    //});

  }
}
