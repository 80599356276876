import React, { useState } from 'react';
import PropTypes from 'prop-types';
import convert from 'convert-units';
import {} from '../style/export';

const DETAILS_STYLE = {
  fontSize: '0.8em',
  margin: '1em 0',
  color: '#666',
};

export default function ExpandableLineItem(props, context) {

  const { topStyle, expansionStyle, expandable, disabled, autoOpened, children, ...rest } = props;
  const [mouseHover, setMouseHover] = useState(false);
  const [expanded, setExpanded] = useState(!!autoOpened);

  const mergedTopStyle = {
    userSelect: !disabled ? 'none' : 'auto',
    cursor: disabled ? 'auto' : mouseHover ? 'pointer' : 'default',
    backgroundColor: mouseHover && !disabled ? '#f0f0f0' : 'transparent',
    padding: '1rem 1rem',
    ...topStyle,
  };

  const mergedExpansionStyle = {
    padding: '0 1rem',
    ...expansionStyle,
  };

  const onClickTop = () => {
    setExpanded(!expanded);
  };

  return (
    <div {...rest}>
      <div
        onMouseEnter={() => setMouseHover(true)}
        onMouseLeave={() => setMouseHover(false)}
        onClick={onClickTop}
        style={mergedTopStyle}
      >
        {children}
      </div>
      {
        expanded && !disabled &&
        <div style={mergedExpansionStyle}>
          {expandable}
        </div>
      }
    </div>
  );
}

ExpandableLineItem.propTypes = {
  expandable: PropTypes.element,
  style: PropTypes.object,
  topStyle: PropTypes.object,
  expansionStyle: PropTypes.object,
  disabled: PropTypes.bool,
  autoOpened: PropTypes.bool,
};
