import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import convert from 'convert-units';
import {
  ContentTitle,
  ContentContainer,
  FormLabel,
  FormBlock,
  FormNumberInput,
  FormSubmitButton,
  CancelButton,
  FormCheckbox
} from '../style/export';
import { calculateProjectEstimateFromScene } from '../../utils/xd-project';
import MaterialLineItem from './material-lineitem';
import LabourLineItem from './labour-lineitem';
import ExpandableLineItem from './expandable-lineitem';

const DEBUG_SHOW_ESTIMATES_JSON = false;

const currencyFormatter = Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
});

export default class ProjectEstimator extends Component {

  constructor(props, context) {
    super(props, context);
    const { estimates, error, errorMsg } = (() => {
      try {
        return {
          estimates: calculateProjectEstimateFromScene(props.state.scene),
          error: null,
        };
      } catch (e) {
        console.warn(e);
        return {
          estimates: null,
          error: e,
        };
      }
    })();
    this.state = {
      estimates,
      error,
      errorMsg,
      hideZeroQuantity: true,
    };
  }

  render() {
    let { state: appState, width, height, units } = this.props;
    let { estimates, error, hideZeroQuantity } = this.state;
    let { projectActions, translator } = this.context;

    const renderEstimates = () => {
      const materialItems = estimates.lineItems.filter(li => !li.isLabour && (!hideZeroQuantity || li.quantity > 0));
      const labourItems = estimates.lineItems.filter(li => li.isLabour && (!hideZeroQuantity || li.quantity > 0));
      return (
        <Fragment>
          <FormLabel>
            <input type="checkbox"
              checked={hideZeroQuantity}
              onChange={e => this.setState({ hideZeroQuantity: e.target.checked })}
            />
            <span>Hide zero-quanity items</span>
          </FormLabel>
          <h2>Material</h2>
          <div style={{ overflowY: 'auto', padding: '0 1rem', maxHeight: '80%' }}>
            {
              materialItems.map(li => <MaterialLineItem
                key={li.id}
                scene={appState.scene}
                estimates={estimates}
                lineItem={li}
                units={units}
                style={{
                  borderTop: materialItems[0] !== li ? '1px solid #ccc' : 'none',
                  padding: '0.5rem',
                }}
              />)
            }
            { materialItems.length === 0 && <div>No material items</div> }
          </div>
          <h2>Labour</h2>
          <div style={{ overflowY: 'auto', padding: '0 1rem', maxHeight: '80%' }}>
            {
              labourItems.map(li => <LabourLineItem
                key={li.id}
                scene={appState.scene}
                estimates={estimates}
                lineItem={li}
                units={units}
                style={{
                  borderTop: labourItems[0] !== li ? '1px solid #ccc' : 'none',
                  padding: '0.5rem',
                }}
              />)
            }
            { labourItems.length === 0 && <div>No labour items</div> }
          </div>
          <table style={{width: '100%', marginTop: '1rem'}}>
            <tbody>
              <tr>
                <td>
                  <h2>
                    Sub-Total: { currencyFormatter.format(estimates.subTotal) }
                  </h2>
                </td>
                <td>
                  <div style={{float: 'right'}}>
                    <CancelButton size='large' onClick={e => projectActions.rollback()}>
                      Leave
                    </CancelButton>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          {
            DEBUG_SHOW_ESTIMATES_JSON &&
            <ExpandableLineItem expandable={
              <div style={{maxHeight: '20%', overflowY: 'auto'}}>
                <pre>
                  {JSON.stringify(estimates, null, 2)}
                </pre>
              </div>
            }>
              Debug Mode
            </ExpandableLineItem>
          }
        </Fragment>
      );
    }

    const renderError = () => {
      return (
        <Fragment>
          <h2>Error</h2>
          <div>{error.message}</div>
          <div>
            <CancelButton size='large' onClick={e => projectActions.rollback()}>
              Leave
            </CancelButton>
          </div>
        </Fragment>
      );
    }

    return (
      <ContentContainer width={width} height={height} style={{overflowY: 'scroll'}}>
        <ContentTitle>Project Estimates</ContentTitle>
        { estimates ? renderEstimates() : renderError() }
      </ContentContainer>
    )
  }
}

ProjectEstimator.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  state: PropTypes.object.isRequired,
  units: PropTypes.string,
};

ProjectEstimator.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};
