import React from 'react';
import PropTypes from 'prop-types';
import {FaSave as IconSave} from 'react-icons/fa';
import ToolbarButton from './toolbar-button';
import {browserDownload}  from '../../utils/browser';
import { Project } from '../../class/export';
import { saveProject } from '../../utils/xd-project';

export default function ToolbarSaveButton({state}, {translator}) {

  const [ sceneHash, setSceneHash ] = React.useState(null);

  let saveProjectToFile = e => {
    e.preventDefault();
    state = Project.unselectAll( state ).updatedState;
    // browserDownload(state.get('scene').toJS());
    saveProject(state.get('scene'));
  };

  React.useEffect(() => {
    const updateHash = e => {
      setSceneHash(state.get('scene').hashCode());
    };
    document.addEventListener('xd-save-success', updateHash);
    document.addEventListener('xd-load', updateHash);
    return () => {
      document.removeEventListener('xd-save-success', updateHash);
      document.removeEventListener('xd-load', updateHash);
    };
  }, [state]);

  let bUnsavedChanges = !!sceneHash && state.get('scene').hashCode() !== sceneHash;

  return (
    <ToolbarButton active={bUnsavedChanges} tooltip={translator.t('Save project')} onClick={saveProjectToFile}>
      <IconSave />
    </ToolbarButton>
  );
}

ToolbarSaveButton.propTypes = {
  state: PropTypes.object.isRequired,
};

ToolbarSaveButton.contextTypes = {
  translator: PropTypes.object.isRequired,
};
