import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Map, List, fromJS} from 'immutable';
import * as SharedStyle from '../../shared-style';
import {TiPlus, TiPencil, TiTrash} from 'react-icons/ti';
import {FaTrash, FaEye, FaLink, FaUnlink} from 'react-icons/fa';
import { IDBroker } from '../../utils/export';
import {FormTextInput, FormNumberInput, Button, FormCheckbox} from '../style/export';
import XDLineItemEditor from './panel-element-editor/xd-line-items-editor/xd-line-item-editor';
import XDLineItemPicker from './panel-element-editor/xd-line-items-editor/xd-line-item-picker';

const styleHoverColor = {color: SharedStyle.SECONDARY_COLOR.main};
const styleAddLabel = {fontSize: '10px', marginLeft: '5px'};
const labelStyle = {fontSize: '1.3em', cursor: 'pointer', textAlign: 'center'};
const labelHoverStyle = {...labelStyle, ...styleHoverColor};

export default class XDLineItemsProjectEditor extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = this.initState(this.props.state);
  }

  //componentWillReceiveProps({ state }) {
  //  this.setState(this.initState(this.props.state));
  //}

  initState(state) {
    return {
      newItemHover: false,
      addItemHover: false,
      addingExistingLineItem: false,
      editingLineItem: null,
      editingLineItemIsNew: false, // When editing and true, onSave will add line item ref to project
    };
  }

  reset() {
    this.setState(this.initState(this.props.state));
  }

  createNewItem() {
    let { lineItemActions } = this.context;
    let newID = IDBroker.acquireID();
    lineItemActions.createLineItem(0, 0, 0, undefined, undefined, undefined, newID);
    console.warn('Created new line item', newID);
    return newID;
  }

  appendLineItem(lineItemID) {
    let { context: { lineItemRefActions } } = this;
    lineItemRefActions.addLineItemToProject(lineItemID);
  }

  updateRefField(lineItemRefID, field, value) {
    let { lineItemRefActions } = this.context;
    let changes = { [field]: value };
    lineItemRefActions.editLineItemInProject(lineItemRefID, changes);
  }

  removeRef(lineItemID) {
    let { context: { lineItemRefActions } } = this;
    lineItemRefActions.removeLineItemFromProject(lineItemID);
  }

  renderLineItem(lineItemRef) {
    let { state } = this.props;
    let lineItem = state.getIn(['scene', 'lineItems', lineItemRef.id]);
    if (!lineItem) {
      console.warn(`Invalid line item reference (id=${lineItemRef.id})`, lineItemRef, lineItem);
      return null;
    }
    const isLabour = lineItem.unit === 'hours';
    let tblStyle = {
      width: '100%',
      borderBottom: '1px #222 solid',
      marginBottom: '0.5em',
      paddingBottom: '0.5em',
    };
    return (
      <table key={lineItemRef.id} style={tblStyle}>
        <tbody>
          <tr>
            <td>Name</td>
            <td>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '0.5em',
              }}>
                <span style={{
                  flex: '1 1 auto',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}>
                  {lineItem.name}
                </span>
                <button
                  title='Remove this item'
                  style={{ float: 'right' }}
                  onClick={() => {
                    if (confirm(`Remove line item "${lineItem.name}"?`)) {
                      this.removeRef(lineItemRef.id);
                    }
                  }}
                >
                  <TiTrash />
                </button>
                <button
                  title='Edit this item'
                  style={{ float: 'right' }}
                  onClick={ () => this.setState({editingLineItem: lineItemRef.id, editingLineItemIsNew: false}) }
                >
                  <TiPencil />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>{ isLabour ? "Hours" : "Quantity" }</td>
            <td>
              <FormNumberInput
                value={lineItemRef.quantity || 0}
                state={state}
                onChange={e => this.updateRefField(lineItemRef.id, 'quantity', e.target.value)}
                immediate={true}
              />
            </td>
          </tr>
          {
            !isLabour && lineItemRef.quantity === 'auto' && !lineItem.minLengthArea &&
            <tr>
              <td colSpan={2}>
                <span style={{
                  color: 'yellow',
                  fontSize: '0.9em',
                }}>
                  Warning: Quantity set to automatic, but no length/area set on this material item.
                  Quantity cannot be calculated until length/area is provided.
                </span>
              </td>
            </tr>
          }
        </tbody>
      </table>
    );
  }

  renderListState() {
    let {
      state: {newItemHover, addItemHover},
      props: {state: appState},
    } = this;
    const lineItems = appState.getIn(['scene', 'lineItemsAdditional']);
    return <div>
      {
        (lineItems.size > 0) &&
          lineItems.entrySeq().map(([lineItemID, lineItemRef]) => {
            return this.renderLineItem(lineItemRef);
          })
      }
      <table style={{width:'100%', marginTop: '0.1em'}}>
        <tbody>
          <tr>
            <td
              style={ !newItemHover ? labelStyle : labelHoverStyle }
              onMouseOver={ () => this.setState({newItemHover: true}) }
              onMouseOut={ () => this.setState({newItemHover: false}) }
              onClick={ e => {
                const newID = this.createNewItem()
                this.setState({editingLineItem: newID, editingLineItemIsNew: true, newItemHover: false});
              }}
            >
              <TiPlus />
              <b style={styleAddLabel}>New Item</b>
            </td>
            <td
              style={ !addItemHover ? labelStyle : labelHoverStyle }
              onMouseOver={ () => this.setState({addItemHover: true}) }
              onMouseOut={ () => this.setState({addItemHover: false}) }
              onClick={ e => this.setState({addingExistingLineItem: true, addItemHover: false}) }
            >
              <TiPlus />
              <b style={styleAddLabel}>Add Existing</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  }

  renderEditState() {
    let {
      state: {editingLineItem, editingLineItemIsNew},
      context: {lineItemActions},
      props: {state: appState},
    } = this;
    return <XDLineItemEditor
      state={appState}
      lineItemID={editingLineItem}
      isNew={!!editingLineItemIsNew}
      onSave={(formData, bAsCopy) => {
        let itemID = editingLineItem;
        if (bAsCopy) {
          // Requesting new copy
          // Delete old line item ref on this project
          this.removeRef(editingLineItem);
          // Replace it with a new one
          itemID = this.createNewItem();
          this.appendLineItem(itemID);
        }
        lineItemActions.editLineItem(itemID, formData);
        if (editingLineItemIsNew) {
          // New line items automatically added to project
          this.appendLineItem(itemID);
        }
        this.setState({editingLineItem: null, editingLineItemIsNew: false});
      }}
      onCancel={() => {
        if (editingLineItemIsNew) {
          // New item was being edited, but was cancelled
          // Delete the new item from scene
          let { lineItemActions } = this.context;
          lineItemActions.removeLineItem(editingLineItem);
        }
        this.setState({editingLineItem: null, editingLineItemIsNew: false})
      }}
    />
  }

  renderPickerState() {
    let {
      props: {state: appState},
    } = this;
    const lineItems = appState.getIn(['scene', 'lineItemsAdditional']);
    return <XDLineItemPicker
      state={appState}
      excludeIDs={lineItems.entrySeq().map(([idx, li]) => li.id).toArray()}
      onAdd={lineItemID => {
        this.appendLineItem(lineItemID);
        this.setState({addingExistingLineItem: false});
      }}
      onCancel={ () => this.setState({addingExistingLineItem: false}) }
    />
  }

  render() {
    let {
      state: {addingExistingLineItem, editingLineItem},
    } = this;
    const uiState =
      (
        !editingLineItem &&
        !addingExistingLineItem &&
        'list'
      ) || (
        editingLineItem &&
        !addingExistingLineItem &&
        'edit'
      ) || (
        addingExistingLineItem &&
        !editingLineItem &&
        'picker'
      ) ||
      'unknown';
    switch (uiState) {
      case 'list':
        return this.renderListState();
      case 'edit':
        return this.renderEditState();
      case 'picker':
        return this.renderPickerState();
      default:
        console.warn(`Unhandled UI state "${uiState}" for XDLineItemsProjectEditor`, this.state);
        return null;
    }
  }
}

XDLineItemsProjectEditor.propTypes = {
  state: PropTypes.object.isRequired,
};

XDLineItemsProjectEditor.contextTypes = {
  lineItemActions: PropTypes.object.isRequired,
  lineItemRefActions: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};
