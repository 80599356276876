import { XD_LINE_ITEM_ACTIONS } from '../xd-constants';

export function createLineItem(baseCost, costPerUnit, minLengthArea, unit = 'ft', minLengthAreaUnit = 'ft', name = "", overrideID = '') {
  return {
    type: XD_LINE_ITEM_ACTIONS.NEW_LINE_ITEM,
    baseCost,
    costPerUnit,
    minLengthArea,
    unit,
    minLengthAreaUnit,
    name,
    overrideID,
  }
}

export function editLineItem(lineItemId, changes) {
  return {
    type: XD_LINE_ITEM_ACTIONS.EDIT_LINE_ITEM,
    lineItemId,
    changes,
  }
}

export function removeLineItem(lineItemId) {
  return {
    type: XD_LINE_ITEM_ACTIONS.REMOVE_LINE_ITEM,
    lineItemId,
  }
}
