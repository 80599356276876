import React from 'react';
import PropTypes from 'prop-types';
import convert from 'convert-units';
import {} from '../style/export';
import ExpandableLineItem from './expandable-lineitem';

const currencyFormatter = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
});

const currencyFormatterPrecise = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
  maximumFractionDigits: 5,
});

const numberFormatter = new Intl.NumberFormat('en-CA', {
  maximumFractionDigits: 2,
});

const DETAILS_STYLE = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '1em',
  margin: '1em 0',
  fontSize: '0.8em',
  color: '#666',
};

/**
 * @param {{
 *   scene: any,
 *   estimates: import('../../utils/xd-project').ProjectEstimate,
 *   lineItem: import('../../utils/xd-project').LineItemComputed
 *   units: string
 * }} props
 */
export default function LabourLineItem(props, context) {

  const { scene, estimates, lineItem, ...rest } = props;

  if (!lineItem.isLabour) {
    throw new Error('Attempted to render material line item as labour line item');
  }

  const units = props.units || scene.units || 'ft';
  const sceneLineItem = scene.lineItems.get(lineItem.id);

  const isExpandable =
    lineItem.walls.length > 0 ||
    lineItem.areas.length > 0 ||
    lineItem.items.length > 0 || lineItem.holes.length > 0 ||
    lineItem.projectAdditional.quantity > 0;

  const getName = (id, type) => {
    const layer = scene.layers.find(layer => layer.get(type).has(id));
    return layer[type].get(id).name;
  }

  const renderTopBody = () => {
    return (
      <table style={{ width: '100%', verticalAlign: 'middle' }}>
        <tbody>
          <tr>
            <td>
              <h3 style={{margin: '0'}}>{sceneLineItem.name}</h3>
            </td>
            <td style={{textAlign: 'right'}}>
              <b>{currencyFormatter.format(lineItem.cost)}</b>
            </td>
          </tr>
          <tr>
            <td>
              Hourly rate: {currencyFormatter.format(sceneLineItem.costPerUnit)}
            </td>
            <td style={{textAlign: 'right'}}>
              Hours: {lineItem.quantity}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  const renderExpandable = () => {
    return (
      <div style={DETAILS_STYLE}>
        {
          lineItem.walls.length > 0 &&
          <div>
            <b>Walls</b> . . . {numberFormatter.format(lineItem.details.walls.totalQuantity)} hrs ({currencyFormatterPrecise.format(lineItem.details.walls.totalCost)})
            <ul>
              {
                lineItem.walls.map(wall => {
                  return (
                    <li key={wall.id}>
                      {`${getName(wall.id, 'lines')} [${wall.id}]`}: {numberFormatter.format(wall.quantity)} hrs . . . {currencyFormatter.format(wall.cost)}
                    </li>
                  );
                })
              }
            </ul>
          </div>
        }
        {
          lineItem.areas.length > 0 &&
          <div>
            <b>Areas</b> . . . {numberFormatter.format(lineItem.details.areas.totalQuantity)} hrs ({currencyFormatterPrecise.format(lineItem.details.areas.totalCost)})
            <ul>
              {
                lineItem.areas.map(area => {
                  return (
                    <li key={area.id}>
                      {`${getName(area.id, 'areas')} [${area.id}]`}: {numberFormatter.format(area.quantity)} hrs . . . {currencyFormatter.format(area.cost)}
                    </li>
                  );
                })
              }
            </ul>
          </div>
        }
        {
          lineItem.items.length > 0 &&
          <div>
            <b>Items</b> . . . {numberFormatter.format(lineItem.details.items.totalQuantity)} hrs ({currencyFormatterPrecise.format(lineItem.details.items.totalCost)})
            <ul>
              {
                lineItem.items.map(item => {
                  return (
                    <li key={item.id}>
                      {`${getName(item.id, 'items')} [${item.id}]`}: {numberFormatter.format(item.quantity)} hrs . . . {currencyFormatter.format(item.cost)}
                    </li>
                  );
                })
              }
            </ul>
          </div>
        }
        {
          lineItem.holes.length > 0 &&
          <div>
            <b>Holes</b> . . . {numberFormatter.format(lineItem.details.holes.totalQuantity)} hrs ({currencyFormatterPrecise.format(lineItem.details.holes.totalCost)})
            <ul>
              {
                lineItem.holes.map(hole => {
                  return (
                    <li key={hole.id}>
                      {`${getName(hole.id, 'holes')} [${hole.id}]`}: {numberFormatter.format(hole.quantity)} hrs . . . {currencyFormatter.format(hole.cost)}
                    </li>
                  );
                })
              }
            </ul>
          </div>
        }
        {
          lineItem.projectAdditional.quantity > 0 &&
          <div>
            <b>Project Additional</b> . . . {numberFormatter.format(lineItem.projectAdditional.quantity)} hrs . . . {currencyFormatter.format(lineItem.projectAdditional.cost)}
          </div>
        }
      </div>
    );
  }

  return (
    <ExpandableLineItem
      expandable={renderExpandable()}
      disabled={!isExpandable}
      {...rest}
    >
      {renderTopBody()}
    </ExpandableLineItem>
  );
}

LabourLineItem.propTypes = {
  estimates: PropTypes.object.isRequired,
  lineItem: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  units: PropTypes.string,
  style: PropTypes.object,
};
