import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormNumberInput from './form-number-input';
import FormSelect from './form-select';
import convert from 'convert-units';

const BASE_STYLE = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'end',
  gap: '1em',
};

export default function FormNumberUnitsInput({
  children,
  style,
  baseUnit,
  valueProps,
  unitProps,
  unit,
  value,
  onChange,
  onChangeUnit,
  immediate,
  unitOptions,
  precision,
  ...rest
}) {
  const [strValue, setStrValue] = useState(convert(value).from(baseUnit).to(unit));
  const [unitValue, setUnitValue] = useState(unit);
  useEffect(() => setUnitValue(unit), [unit]);
  return <div style={{...BASE_STYLE, ...style}} {...rest}>
    <FormNumberInput
      value={strValue}
      onChange={e => {
        setStrValue(e.target.value);
        const val = convert(parseFloat(e.target.value)).from(unit).to(baseUnit);
        onChange(val);
        console.warn(val, baseUnit);
      }}
      immediate={immediate}
      precision={precision}
      style={{ width: 'unset' }}
      {...valueProps}
    />
    <FormSelect
      value={unitValue}
      onChange={e => {
        const from = unitValue;
        const to = e.target.value;
        if (from === to) return;
        const converted = convert(parseFloat(strValue)).from(from).to(to);
        setStrValue(converted);
        setUnitValue(to);
        onChangeUnit(to);
      }}
      precision={precision}
      style={{ width: 'unset' }}
      {...unitProps}
    >
      { unitOptions.map(({ value, label }) => <option key={value} value={value}>{label}</option>) }
    </FormSelect>
    {children}
  </div>
}

FormNumberUnitsInput.defaultProps = {
  baseUnit: 'cm',
  unit: 'cm',
  value: 0,
  onChange: (v) => {},
  onChangeUnit: (v) => {},
  immediate: false,
  unitOptions: [
    { value: 'cm', label: 'Centimetres' },
    { value: 'ft', label: 'Feet/In' },
    { value: 'm', label: 'Metres' },
  ],
  precision: 6,
};

FormNumberUnitsInput.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  baseUnit: PropTypes.string,
  valueProps: PropTypes.object,
  unitProps: PropTypes.object,
  unit: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  onChangeUnit: PropTypes.func,
  immediate: PropTypes.bool,
  unitOptions: PropTypes.array,
  precision: PropTypes.number,
};
