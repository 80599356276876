import React from 'react';

const BASE_STYLE = {
  fontSize: '0.8em',
  color: '#999',
  lineHeight: '1.5',

};

export default function HintText({children, style, ...rest}) {
  return <div style={{...BASE_STYLE, ...style}} {...rest}>{children}</div>
}
