import React from 'react';
import PropTypes, { number } from 'prop-types';
import convert from 'convert-units';
import {} from '../style/export';
import ExpandableLineItem from './expandable-lineitem';

const currencyFormatter = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
});

const currencyFormatterPrecise = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
  maximumFractionDigits: 5,
});

const numberFormatter = new Intl.NumberFormat('en-CA', {
  maximumFractionDigits: 2,
});

const DETAILS_STYLE = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '1em',
  margin: '1em 0',
  fontSize: '0.8em',
  color: '#666',
};

/**
 * @param {{
 *   scene: any,
 *   estimates: import('../../utils/xd-project').ProjectEstimate,
 *   lineItem: import('../../utils/xd-project').LineItemComputed
 *   units: string
 * }} props
 */
export default function MaterialLineItem(props, context) {

  const { scene, estimates, lineItem, ...rest } = props;

  if (lineItem.isLabour) {
    throw new Error('Attempted to render labour line item as material line item');
  }

  const units = props.units || scene.units || 'ft';
  const sceneLineItem = scene.lineItems.get(lineItem.id);

  const totalLength = lineItem.walls.reduce((acc, wall) => {
    const computedWall = estimates.wallsComputed.find(w => w.id === wall.id);
    acc += convert(computedWall.length).from(computedWall.unit).to(units) * wall.quantity;
    return acc;
  }, 0);

  const totalArea = lineItem.areas.reduce((acc, area) => {
    const computedArea = estimates.areasComputed.find(a => a.id === area.id);
    acc += convert(computedArea.area).from(`${computedArea.unit}2`).to(`${units}2`) * area.quantity;
    return acc;
  }, 0);

  const getName = (id, type) => {
    const layer = scene.layers.find(layer => layer.get(type).has(id));
    return layer[type].get(id).name;
  }

  const renderTopBody = () => {
    return (
      <table style={{ width: '100%', verticalAlign: 'middle' }}>
        <tbody>
          <tr>
            <td>
              <h3 style={{margin: '0'}}>{sceneLineItem.name}</h3>
            </td>
            <td style={{textAlign: 'right'}}>
              <b>{currencyFormatter.format(lineItem.cost)}</b>
            </td>
          </tr>
          <tr>
            <td>
              {
                !!totalLength ?
                  <div title={`Total wall length: ${totalLength} ${units}`}>
                    Total wall length: {numberFormatter.format(totalLength)} {units}
                  </div> :
                  null
              }
              {
                !!totalArea ?
                  <div title={`Total area: ${totalArea} ${units}²`}>
                    Total area: {numberFormatter.format(totalArea)} {units}²
                  </div> :
                  null
              }
            </td>
            <td style={{textAlign: 'right'}}>
              Qty: {lineItem.quantity}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  const renderExpandable = () => {
    let rawQuantity = numberFormatter.format(lineItem.details.rawQuantity);
    if (lineItem.details.materialExcess - 1 > 0) {
      rawQuantity = `${rawQuantity} includes ${(numberFormatter.format(lineItem.details.materialExcess-1))*100}% excess`;
    }
    return (
      <div style={DETAILS_STYLE}>
        <div>
          <div><b>Base cost:</b> {currencyFormatter.format(sceneLineItem.baseCost)}</div>
          <div><b>Cost per {sceneLineItem.unit}:</b> {currencyFormatterPrecise.format(sceneLineItem.costPerUnit)}</div>
          <div><b>Minimum length/area per qty:</b> {numberFormatter.format(sceneLineItem.minLengthArea)} {sceneLineItem.minLengthAreaUnit}/{sceneLineItem.minLengthAreaUnit}&sup2;</div>
        </div>
        <div>---</div>
        <div>
          <div>
            <b>Quantity</b> . . . {lineItem.quantity} ({rawQuantity}) &times; {currencyFormatterPrecise.format(sceneLineItem.baseCost)} . . . {currencyFormatter.format(lineItem.quantity * sceneLineItem.baseCost)}
          </div>
        </div>
        {
          lineItem.walls.length > 0 &&
          <div>
            <b>+ Walls</b> . . . {currencyFormatterPrecise.format(lineItem.details.walls.totalCost)}
            <ul>
              {
                lineItem.walls.map(wall => {
                  const computedWall = estimates.wallsComputed.find(w => w.id === wall.id);
                  return (
                    <li key={wall.id}>
                      {`${getName(wall.id, 'lines')} [${wall.id}]`}: {numberFormatter.format(wall.quantity)} &times; {numberFormatter.format(convert(computedWall.length).from(computedWall.unit).to(units))} {units} . . . {currencyFormatterPrecise.format(wall.cost)}
                    </li>
                  );
                })
              }
            </ul>
          </div>
        }
        {
          lineItem.areas.length > 0 &&
          <div>
            <b>+ Areas</b> . . . {currencyFormatter.format(lineItem.details.areas.totalCost)}
            <ul>
              {
                lineItem.areas.map(area => {
                  const computedArea = estimates.areasComputed.find(a => a.id === area.id);
                  return (
                    <li key={area.id}>
                      {`${getName(area.id, 'areas')} [${area.id}]`}: {numberFormatter.format(area.quantity)} &times; {numberFormatter.format(convert(computedArea.area).from(`${computedArea.unit}2`).to(`${units}2`))} {units}² . . . {currencyFormatterPrecise.format(area.cost)}
                    </li>
                  );
                })
              }
            </ul>
          </div>
        }
        {
          lineItem.items.length > 0 &&
          <div>
            <b>+ Items</b>
            <ul>
              {
                lineItem.items.map(item => {
                  return (
                    <li key={item.id}>
                      {`${getName(item.id, 'items')} [${item.id}]`}: {numberFormatter.format(item.quantity)}
                    </li>
                  );
                })
              }
            </ul>
          </div>
        }
        {
          lineItem.holes.length > 0 &&
          <div>
            <b>+ Holes</b>
            <ul>
              {
                lineItem.holes.map(hole => {
                  return (
                    <li key={hole.id}>
                      {`${getName(hole.id, 'holes')} [${hole.id}]`}: {numberFormatter.format(hole.quantity)}
                    </li>
                  );
                })
              }
            </ul>
          </div>
        }
        {
          lineItem.projectAdditional.quantity > 0 &&
          <div>
            <b>+ Project Additional:</b>
            <div>
              {numberFormatter.format(lineItem.projectAdditional.quantity)}
            </div>
          </div>
        }
      </div>
    );
  }

  return (
    <ExpandableLineItem
      expandable={renderExpandable()}
      {...rest}
    >
      {renderTopBody()}
    </ExpandableLineItem>
  );
}

MaterialLineItem.propTypes = {
  estimates: PropTypes.object.isRequired,
  lineItem: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  units: PropTypes.string,
};
